import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebSoundService {

  private audioContext: AudioContext;

  constructor() {
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  }

  public playBeep(type: OscillatorType, duration: number, frequency: number, volume: number): void {
    const oscillator = this.audioContext.createOscillator();
    const gainNode = this.audioContext.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(this.audioContext.destination);

    oscillator.frequency.value = frequency;
    oscillator.type = type;
    gainNode.gain.value = volume;

    oscillator.start();
    setTimeout(() => {
      oscillator.stop();
    }, duration);
  }

  public playBell(duration: number, frequency: number, volume: number): void {
    const oscillator = this.audioContext.createOscillator();
    const gainNode = this.audioContext.createGain();
  
    oscillator.connect(gainNode);
    gainNode.connect(this.audioContext.destination);
  
    oscillator.frequency.value = frequency;
    oscillator.type = 'sine';
    gainNode.gain.value = volume;
  
    oscillator.start();
    gainNode.gain.exponentialRampToValueAtTime(0.00001, this.audioContext.currentTime + duration / 1000);
    setTimeout(() => {
      oscillator.stop();
    }, duration);
  }
  

}

