import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileModel } from 'src/app/swagger';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { Router } from '@angular/router';
import { HelpSupportComponent } from '../help-support/help-support.component';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent implements OnInit {

  public static readonly id = 'profile-options-modal';

  public userProfile: ProfileModel | null = null;
  public userFullName = '';
  public dashboardLink: string | null = null;
  public userType: string | null = null
  private currentPopover: HTMLIonPopoverElement | null = null;

  constructor(
    private popoverController: PopoverController,
    private authService: AuthService,
    private userProfileService: UserProfileService,
    private router: Router,
  ) { }

  public onItemClick(_: MouseEvent) {
    this.popoverController.dismiss('', '', ProfilePopoverComponent.id);
  }

  public onDashboardClick(_: Event) {
    this.router.navigateByUrl(this.dashboardLink);
    this.popoverController.dismiss('', '', ProfilePopoverComponent.id);
  }

  public onLogoutClick(_: MouseEvent) {
    this.authService.clearSession();
    this.popoverController.dismiss("logout", "", ProfilePopoverComponent.id);
    this.userProfileService.setUserProfile(null);
    this.router.navigateByUrl('/');
  }

  public onSwitchClick(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.authService.clearSession();
    this.popoverController.dismiss("switch", "", ProfilePopoverComponent.id);
    this.router.navigateByUrl('/login');
  }

  public ngOnInit() {
    this.init();
  }

  private init() {
    this.setUserProfile();
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userFullName = '';
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
        if(userProfile){
          this.userType = this.userProfile.roles[0];
          this.updateDashboardLink(this.userType);
        }
        if ( userProfile && (userProfile.firstName && userProfile.lastName)) {
          this.userFullName = userProfile.firstName + ' ' + userProfile.lastName;
        }
      }
    });
  }

  public updateDashboardLink(user_type:string){
    if(user_type === 'Users'){
      this.dashboardLink = '/customer/home';
    }else if(user_type === 'RestaurantAdmins'){
      this.dashboardLink = '/resto-admin/home';
    }else if(user_type === 'Admins'){
      this.dashboardLink = '/admins/home';
    }
  }

  // public async openHelpAndSupport(event: MouseEvent) {
  //   if (this.currentPopover) {
  //     await this.currentPopover.dismiss();
  //     this.currentPopover = null;
  //   } else {
  //     this.currentPopover = await this.popoverController.create({
  //       id: HelpSupportComponent.id,
  //       component: HelpSupportComponent,
  //       event,
  //       translucent: true,
  //       cssClass: 'custom-popover'
  //     });

  //     this.currentPopover.onDidDismiss().then(() => {
  //       this.currentPopover = null;
  //     });

  //     await this.currentPopover.present();
  //   }
  // }

}
