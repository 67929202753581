import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {
  RestaurantBranchRestControllerService,
  RestaurantEntity,
  RestaurantRestControllerService,
  RestaurantBranchEntity,
  RestaurantModel,
  SmartDineResponseRestaurantModel,
  CurrencyModel,
  RestaurantCurrencyService,
} from 'src/app/swagger';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {

  private readonly storageKey = 'frequently-visited-restaurants';
  private fvrSubject: BehaviorSubject<RestaurantModel[]> = new BehaviorSubject<RestaurantModel[]>([]);

  private subject: ReplaySubject<RestaurantEntity[]> = new ReplaySubject(1);

  private selectedRestaurantId: string | null = null;
  private selectedRestaurantIdSubject: ReplaySubject<string | null> = new ReplaySubject(1);

  private selectedBranchId: string | null = null;
  private selectedBranchIdSubject: ReplaySubject<string | null> = new ReplaySubject(1);

  private isAvailableOrLoading = false;

  private branchesSubject: ReplaySubject<RestaurantBranchEntity[]> = new ReplaySubject(1);

  private subscription;

  private logoUrlSubject: ReplaySubject<string | null> = new ReplaySubject<string | null>(null);

  private selectedRestaurantSubject = new BehaviorSubject<RestaurantModel | null>(null);
  public selectedRestaurant$ = this.selectedRestaurantSubject.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private restaurantRestControllerService: RestaurantRestControllerService,
    private restaurantBranchRestControllerService: RestaurantBranchRestControllerService, // TODO
    private restaurantCurrencySerice: RestaurantCurrencyService
  ) {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.setSelectedRestaurantId(queryParams?.restaurantId || null, { updateRoute: false });
      this.setSelectedBranchId(queryParams?.restaurantBranchId || null, { updateRoute: false });
      Promise.resolve().then(() => {
        this.subscription?.unsubscribe();
      });
    });

    this.init();
  }

  public init() {
    this.loadFrequentlyVisitedRestaurants();
  }

  public getFrequentlyVisitedRestaurants() {
    return this.fvrSubject.asObservable();
  }

  public setFrequentlyVisitedRestaurants(restaurants: RestaurantModel[]) {
    this.storageService.set(this.storageKey, restaurants);
    this.fvrSubject.next(restaurants);
  }

  public updateFrequentlyVisitedRestaurants(restaurant: RestaurantModel) {
    let restaurants: RestaurantModel[];
    restaurants = this.fvrSubject.getValue();
    restaurants = restaurants.filter(e => e.restaurantId !== restaurant.restaurantId);
    restaurants.unshift(restaurant);
    this.setFrequentlyVisitedRestaurants(restaurants);
  }

  private async loadFrequentlyVisitedRestaurants() {
    const restaurants: RestaurantModel[] = await this.storageService.get(this.storageKey);
    if (Array.isArray(restaurants) && restaurants.length) {
      this.fvrSubject.next(restaurants);
    }
  }

  public getSelectedRestaurantId(): Observable<string | null> {
    return this.selectedRestaurantIdSubject.asObservable();
  }

  public setSelectedRestaurantId(restaurantId: string | null, options = { updateRoute: true }) {
    this.selectedRestaurantId = restaurantId;
    this.selectedRestaurantIdSubject.next(restaurantId);

    // Updating URL query params..
    if (options.updateRoute) {
      const queryParams = {
        restaurantId: this.selectedRestaurantId,
        restaurantBranchId: this.selectedBranchId,
      };
      this.router.navigate([], { queryParams, queryParamsHandling: 'merge', replaceUrl: true });
    }

    // Updating branch options..
    if (restaurantId) {
      this.loadBranches(restaurantId);
    }
  }

  public getRestaurants(forceRefresh = false): Observable<RestaurantEntity[]> {
    if (forceRefresh || !this.isAvailableOrLoading) {
      this.isAvailableOrLoading = true;
      this.loadRestaurants();
    }
    return this.subject.asObservable();
  }

  public setRestaurants(restaurants: RestaurantEntity[]) {
    this.subject.next(restaurants);
  }

  private loadRestaurants() {
    this.restaurantRestControllerService.getRestaurants1().subscribe({
      next: (res: SmartDineResponseRestaurantModel) => {
        this.setRestaurants(res.items);
        if (res.items.length && !this.selectedRestaurantId) {
          console.log('restaurant', res.items[0])
          this.setSelectedRestaurantId(res.items[0].restaurantId);
          this.setLogoUrl(res.items[0].iconUrl);
          const prefCurrency: CurrencyModel = {
            currencyCode: res.items[0].prefCurrency.currencyCode,
            currencysymbol: res.items[0].prefCurrency.currencysymbol,
            displayName: res.items[0].prefCurrency.displayName
          };
          this.restaurantCurrencySerice.setPrefCurrency(prefCurrency);
        }
      },
      error: (err) => {
        console.error('Unable to get restaurants!', err);
        this.isAvailableOrLoading = false;
        this.setLogoUrl(null);
      }
    });
  }

  public getSelectedBranchId(): Observable<string | null> {
    return this.selectedBranchIdSubject.asObservable();
  }

  public setSelectedBranchId(branchId: string | null, options = { updateRoute: true }) {
    this.selectedBranchId = branchId;
    this.selectedBranchIdSubject.next(branchId);

    // Updating URL query params..
    if (options.updateRoute) {
      const queryParams = {
        restaurantId: this.selectedRestaurantId,
        restaurantBranchId: this.selectedBranchId,
      };
      this.router.navigate([], { queryParams, queryParamsHandling: 'merge', replaceUrl: true });
    }
  }

  public getBranches(forceRefresh = false): Observable<RestaurantBranchEntity[]> {
    if (forceRefresh && this.selectedRestaurantId) {
      this.loadBranches(this.selectedRestaurantId);
    }
    return this.branchesSubject.asObservable();
  }

  public setBranches(branches: RestaurantBranchEntity[]) {
    this.branchesSubject.next(branches);
  }

  private loadBranches(restaurantId: string) {
    this.restaurantBranchRestControllerService.getRestaurantBranches1(restaurantId).subscribe({
      next: (res) => {
        this.setBranches(res.items);
      },
      error: (err) => {
        console.error('Unable to get branches!', err);
      }
    });
  }

  public getLogoUrl(): Observable<string | null> {
    return this.logoUrlSubject.asObservable();
  }

  public setLogoUrl(url: string | null): void {
    this.logoUrlSubject.next(url);
  }

  public setSelectedRestaurant(restaurant: RestaurantModel | null) {
    this.selectedRestaurantSubject.next(restaurant);
  }

  public getSelectedRestaurant(): RestaurantModel | null {
    return this.selectedRestaurantSubject.value;
  }

}
