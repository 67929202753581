import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { SmartdineImageRestControllerService, RestaurantEntity, ProfileModel, RestaurantRestControllerService, GeoLocation, SmartDineResponseRestaurantModel, CurrencyModel, RestaurantModelPrefTimeZone} from 'src/app/swagger';
import { LoaderService } from '../../services/loader/loader.service';
import { RestaurantAdminModel } from 'src/app/swagger/model/restaurantAdminModel';
import { AddressComponent } from '../maps/address/address.component';
import { RestaurantRegistrationForAdminService } from 'src/app/swagger/api/restaurantRegistrationForAdmin.service';
import { SmartDineResponseRestaurantForAdmin } from 'src/app/swagger/model/smartDineResponseRestaurantForAdmin';

import { COUNTRY_CODE, COUNTRY_LIST, CURRENCY_CODES, CURRENCY_LIST, INDIAN_STATES, TIME_ZONES, USA_STATES } from './data-list.helper';

@Component({
  selector: 'app-restaurant-upsertion',
  templateUrl: './restaurant-upsertion.component.html',
  styleUrls: ['./restaurant-upsertion.component.scss'],
})

export class RestaurantUpsertionComponent implements OnInit {

  public static readonly id = 'resto-upsertion-modal';

  @Input() public restaurantId: string | null = null;
  @Input() restaurantTitle: string = 'New Restaurant';

  public restaurantEntity: RestaurantEntity | null = null;
  public restaurantAdminEntity: RestaurantAdminModel | null = null;
  public userProfile: ProfileModel | null = null;
  public restaurantForm: FormGroup | null = null;
  public countries: string[] = COUNTRY_LIST;
  public timeZones: RestaurantModelPrefTimeZone[] = [];
  public indianStates: string[] = INDIAN_STATES;
  public usaStates: string[] = USA_STATES;
  public countryCodes: string[] = COUNTRY_CODE;
  public currencyList: CurrencyModel[] = CURRENCY_LIST;
  public currencyCodes: string[] = CURRENCY_CODES;
  public selectedCountry: string = 'India';
  public selectedTimeZone: string = 'Asia/Kolkata';
 
  get restaurantStatus() {
    return Object.keys(RestaurantAdminModel.RestaurantStatusEnum);
  }

  get orderTypes() {
    return Object.keys(RestaurantAdminModel.AllowedOrderTypesEnum);
  }

  get paymentPreferences() {
    return Object.keys(RestaurantAdminModel.AllowedPaymentPreferencesEnum);
  }

  constructor(
    private modalController: ModalController,
    private userProfileService: UserProfileService,
    public formBuilder: FormBuilder,
    private restaurantRestControllerService: RestaurantRestControllerService,
    private toastController: ToastController,
    private loaderService: LoaderService,
    private smartdineImageRestControllerService: SmartdineImageRestControllerService,
    private registrationServiceForAdmin: RestaurantRegistrationForAdminService
  ) {
    this.createForm();
  }

  private createForm() {
    this.restaurantForm = this.formBuilder.group({
      restaurantName: new FormControl('', [Validators.required]),
      restaurantLogoUrl: new FormControl('', []),
      restaurantDescription: new FormControl('', []),
      restaurantCode: new FormControl('', []),
      fssai: new FormControl('', []),
      gstId: new FormControl('', []),
      restaurantStatus: new FormControl('', []),
      prefTimeZone: new FormControl('', []),
      maxNoOfTables: new FormControl(1, []),
      maxNoOfRestaurantBranches: new FormControl(1, []),
      prefCurrencyCode: new FormControl('', []),

      restaurantBranchCode: new FormControl('', []),
      restaurantBranchName: new FormControl('', []),
      restaurantBranchFssai: new FormControl('', []),
      restaurantBranchGstId: new FormControl('', []),
      restaurantBranchEmailId: new FormControl('', []),
      restaurantBranchContactNo: new FormControl('', []),
      restaurantBranchAddress: new FormControl('', []),
      restaurantBranchWebsite: new FormControl('', []),
      restaurantBranchCity: new FormControl('', []),
      restaurantCountry: new FormControl('India', []),
      restaurantBranchState: new FormControl('', []),
      restaurantBranchCountryCode: new FormControl('+91', []),
      allowedPaymentPreferences: new FormControl('', []),
      allowedOrderTypes: new FormControl('', []),
      restaurantAdminUserId: new FormControl('', []),
      restaurantSubscriptionEntitiy: new FormControl('', []),
      location: new FormControl(
        { latitude: 0, longitude: 0 } as GeoLocation,
        []
      ),
    });
  }

  public ngOnInit() {
    this.setUserProfile();
    this.updateTimeZones(this.selectedCountry);
    if (this.restaurantId) {
      this.setRestaurant();
    } 
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      },
    });
  }

  private setRestaurant() {
    this.restaurantRestControllerService.getRestaurant(this.restaurantId).subscribe({
        next: (res) => {
          this.restaurantEntity = res.item;

          this.restaurantForm.patchValue({
            restaurantId: this.restaurantEntity?.restaurantId ?? '',
            restaurantCode: this.restaurantEntity?.restaurantCode ?? '',
            restaurantName: this.restaurantEntity?.name ?? '',
            restaurantDescription: this.restaurantEntity?.description ?? '',
            restaurantLogoUrl: this.restaurantEntity?.iconUrl ?? '',
            gstId: this.restaurantEntity?.gstIN ?? '',
            iconImage: this.restaurantEntity?.iconImage ?? '',
            restaurantImages: this.restaurantEntity?.restaurantImages ?? '',
            restaurantStatus: this.restaurantEntity?.restaurantStatus ?? '',
            validUpto: this.restaurantEntity?.validUpto ?? '',
            maxNoOfTables: this.restaurantEntity?.maxNoOfTables ?? 0,
            maxNoOfRestaurantBranches: this.restaurantEntity?.maxNoOfRestaurantBranches ?? 0,
            active: this.restaurantEntity?.active ?? false,
            restaurantSubscriptionEntitiy: this.restaurantEntity?.restaurantSubscriptionEntityId ?? 'basic',
            fssai: this.restaurantEntity?.fssai ?? '',
            prefCurrencyCode: this.restaurantEntity?.prefCurrencyCode ?? null,
            prefTimeZone: this.restaurantEntity?.prefTimeZone ?? null,
            prefCurrency: this.restaurantEntity?.prefCurrency ?? null,
            prefTimeZoneId: this.restaurantEntity?.prefTimeZoneId ?? null,
          });

        },
        error: (err) => {
          console.error('Unable to get Restaurant details!', err);
        },
      });
  }

  public onCloseClick() {
    this.modalController.dismiss('', '', RestaurantUpsertionComponent.id);
  }

  public onSaveClick() {
    if (this.restaurantForm?.valid && !this.restaurantId) {
      this.restaurantForm?.markAllAsTouched();
      this.registerRestaurant();
    } else if (this.restaurantForm?.valid && this.restaurantId) {
      this.restaurantForm?.markAllAsTouched();
      this.updateRestaurant();
    }
  }

  private registerRestaurant() {

    const restaurantModel: RestaurantAdminModel = this.restaurantAdminEntity;
    Object.assign(restaurantModel, this.restaurantForm.value);

    restaurantModel['restaurantId'] = restaurantModel.restaurantName.replace(/\s+/g, '') + 'RestaurantId';
    restaurantModel['restaurantBranchId'] = restaurantModel.restaurantBranchName.replace(/\s+/g, '') + 'BranchId';

    this.registerNewRestaurant(restaurantModel);
  }

  private registerNewRestaurant(restaurantModel: RestaurantAdminModel) {
    this.registrationServiceForAdmin.createRestaurant(restaurantModel).subscribe({
        next: (res: SmartDineResponseRestaurantForAdmin) => {

          let data = { refresh: 'true', type: 'created', restaurant: res.item };
          this.modalController.dismiss(data, '', RestaurantUpsertionComponent.id);
          this.showToast(`Restaurant - ${res.item['name']} Created Successfully!`);

        },
        error: (err) => {
          console.error('Unable to create Restaurant!', err);
          this.showToast(
            `Restaurant - ${restaurantModel.restaurantName} Creation Failed!`
          );
        },
      });
  }

  private updateRestaurant() {
    const formattedFormData: RestaurantEntity = this.mapFormToValidEntity(
      this.restaurantForm?.value
    );
    this.updatedRestaurant(formattedFormData);
  }

  private updatedRestaurant(restaurantModel: RestaurantEntity) {
    this.restaurantRestControllerService.updateRestaurant(restaurantModel, this.restaurantId).subscribe({
        next: (res: SmartDineResponseRestaurantModel) => {

          let data = { refresh: 'true', type: 'updated', restaurant: res.item };
          this.modalController.dismiss( data, '', RestaurantUpsertionComponent.id);
          this.showToast( `Restaurant - ${res.item['name']} Updated Successfully!`);

        },
        error: (err) => {
          console.error('Unable to create Restaurant!', err);
          this.showToast(`Restaurant - ${restaurantModel.name} Creation Failed!`);
        },
      });
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
    });
    toast.present();
  }

  /**
   * Method handler to be called on image file browsing/drop.
   */
  onImageSelection(event) {
    const files = event.target.files;
    if (files.length && this.restaurantEntity.restaurantId) {
      this.uploadImage(files[0]);
    }
  }

  /**
   * Upload Image.
   * @param file Image File.
   */
  uploadImage(imageFile: any) {
    const restaurantId = this.restaurantEntity.restaurantId; // TODO: Bug in API; Need fixing..
    const fileKey = this.restaurantEntity?.iconImage?.key;
    let uploadRequest = this.smartdineImageRestControllerService.uploadFileForm(
      imageFile,
      this.restaurantId
    );
    if (fileKey) {
      this.loaderService.showLoader();
      uploadRequest = this.smartdineImageRestControllerService.updateFileForm(
        fileKey,
        imageFile,
        this.restaurantId
      );
    }

    this.loaderService.showLoader();
    uploadRequest.subscribe(
      (res) => {
        console.info('Image uploaded successfully!');
        this.restaurantEntity.iconImage = res.item;
        this.loaderService.hideLoader();
      },
      (err) => {
        console.error('Unable to upload image!', err);
        this.loaderService.hideLoader();
      }
    );
  }

  public onCountryChange(event: Event) {

    this.selectedCountry = event['detail']?.value;
    this.updateTimeZones(this.selectedCountry);

    if (event['detail'].value === 'India') {
      this.restaurantForm.get('restaurantBranchState').setValue('');
    } else if (event['detail'].value === 'USA') {
      this.restaurantForm.get('restaurantBranchState').setValue('');
    }

  }

  public showMap(locationFormControl: any) {
    this.showMapPopup(locationFormControl);
  }

  public async showMapPopup(locationControl: any) {
    const geolocation: GeoLocation = locationControl.value;
    const model = await this.modalController.create({
      component: AddressComponent,
      id: AddressComponent.id,
      componentProps: {
        location: geolocation,
      },
    });
    model.onDidDismiss().then((selLocation) => {
      locationControl.setValue({
        latitude: selLocation?.data?.location?.lat,
        longitude: selLocation?.data?.location?.lng,
      });
    });
    await model.present();
  }

  private mapFormToValidEntity(formValue: RestaurantAdminModel) {
    return {
      restaurantId: this.restaurantId,
      restaurantCode: formValue?.restaurantCode ?? '',
      name: formValue?.restaurantName ?? '',
      description: formValue?.restaurantDescription ?? '',
      iconUrl: formValue?.restaurantLogoUrl ?? '',
      gstIN: formValue?.gstId ?? '',
      fssai: formValue?.fssai ?? '',
      restaurantStatus: formValue?.restaurantStatus ?? 'PUBLISHED',
      maxNoOfTables: formValue?.maxNoOfTables ?? 0,
      maxNoOfRestaurantBranches: formValue?.maxNoOfRestaurantBranches ?? 0,
      restaurantSubscriptionEntityId: formValue?.restaurantSubscriptionEntitiy ?? 'basic',
      prefTimeZoneId: formValue?.prefTimeZone ?? null,
      prefTimeZone: this.timeZones?.find(time => time.id === formValue?.prefTimeZoneId) ?? null,
      prefCurrencyCode: formValue?.prefCurrencyCode ?? null,
      prefCurrency: this.currencyList.find(currency => currency.currencyCode === formValue?.prefCurrencyCode) ?? null,
    };
  }

  private updateTimeZones(country: string) {

    if (country === 'India') {

      this.timeZones = TIME_ZONES.filter(zone => zone.id.startsWith('Asia'));
      this.selectedTimeZone = this.timeZones.find(t => t.id.startsWith('Asia')).id;

    } else if (country === 'USA') {

      this.timeZones = TIME_ZONES.filter(zone => zone.id.startsWith('US'));
      this.selectedTimeZone = this.timeZones.find(t => t.id.startsWith('US')).id;

    }

  }

}
