import { Component, OnInit } from '@angular/core';
import { AlertController, MenuController, PopoverController } from '@ionic/angular';
import { Action } from '../../services/header/header.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { ProfileModel } from '../../../swagger';
import { Router } from '@angular/router';
import { HomeComponent } from 'src/app/core/components/home/home.component';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../services/storage/storage.service';
import { RestaurantsService } from '../../services/restaurants/restaurants.service';
import { NotificationComponent } from '../notification/notification.component';
import { NotificationService } from '../../services/notification/notification.service';
import { ProfilePopoverComponent } from '../profile-popover/profile-popover.component';
import { HelpSupportComponent } from '../help-support/help-support.component';

const languages = [
  {
    name: 'English',
    code: 'en',
  },
  // {
  //   name: 'English(IN)',
  //   code: 'en-IN',
  // },
  // {
  //   name: 'हिंदी (Hindi)',
  //   code: 'hi-IN',
  // },
  // {
  //   name: 'বাঙালি (Bangali)',
  //   code: 'ba-IN',
  // },
  // {
  //   name: 'తెలుగు (Telugu)',
  //   code: 'te-IN',
  // },
  // {
  //   name: 'தமிழ் (Tamil)',
  //   code: 'ta-IN',
  // },
  
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  private readonly storageKeyLangauge = 'selected-language';

  public userProfile: ProfileModel | null = null;
  public actions: Action[] = [];

  public customLogoLocation: string = null;

  public languages = languages;

  public selectedLanguage = 'en';

  public logoUrl:string | null = null;

  public notificationCount: number = 0;

  constructor(
    private menuController: MenuController,
    private popoverController: PopoverController,
    private userProfileService: UserProfileService,
    public alertController: AlertController,
    private storageService: StorageService,
    private translateService: TranslateService,
    private router: Router,
    private restaurantService: RestaurantsService,
    private notificationService: NotificationService
  ) {

    const parsedUrl = new URL(window.location.href);

    if (parsedUrl && (parsedUrl.hostname == 'dev-bmgrand.smartdinenow.com' || parsedUrl.hostname == 'bmgrand.smartdinenow.com')) {
      this.customLogoLocation = 'assets/img/bmgrandlogo.png'
    }

  }


  public async onLanguageChange() {
   const langCode = this.selectedLanguage;
   this.translateService.use(langCode);
   await this.storageService.set(this.storageKeyLangauge, langCode);
  }


  public ngOnInit() {
    this.init();
    this.getRestaurantLogo();
    this.getCurrentCount();
  }

  private getCurrentCount(){
    this.notificationService.notificationCount$.subscribe(count => {
      this.notificationCount = count;
    });
  }

  public async onMenuClick(_: Event) {
    await this.menuController.enable(true, HomeComponent.sideMenuId);
    await this.menuController.toggle(HomeComponent.sideMenuId);
  }

  public onActionClick(event: Event, action: Action) {
    if (action.handler) {
      action.handler(event);
    }
  }

  public ionViewWillEnter() {
    this.menuController.enable(true, HomeComponent.sideMenuId);
  }

  public async openProfilePopover(event: MouseEvent) {
    const popover = await this.popoverController.create({
      id: ProfilePopoverComponent.id,
      component: ProfilePopoverComponent,
      event,
      translucent: true
    });
    popover.onDidDismiss().then((res)=>{
      if(!res || !res.data) return;
      if(res.data === "logout"){
        this.userProfile = null;
        this.setUserProfile();
      }else if(res.data === "switch"){
        this.router.navigateByUrl('/login');
      }
    });
    return await popover.present();
  }

  public async openHelpAndSupport(event: MouseEvent) {
    const popover = await this.popoverController.create({
      id: HelpSupportComponent.id,
      component: HelpSupportComponent,
      event,
      translucent: true
    });
    popover.onDidDismiss().then((res)=>{
      if(!res || !res.data) return;
    });
    return await popover.present();
  }

  private init() {
    this.setUserProfile();
    this.setLanguage();
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
  }

  private setLanguage() {
    this.storageService.get(this.storageKeyLangauge).then(
      (langCode: string | null) => {
        if (langCode) {
          this.selectedLanguage = langCode;
          this.translateService.use(langCode);
        }
      },
    );
  }

  private getRestaurantLogo(){
    this.restaurantService.getLogoUrl().subscribe({
      next: (url: string |null) =>{
        this.logoUrl = url;
      },
      error: (error) =>{
        console.log('Can not able to get the Logo!', error.message);
      }
    })
  }

  public async openNotifications(event: Event) {
    const popover = await this.popoverController.create({
      component: NotificationComponent,
      event: event,
      translucent: true
    });
    return await popover.present();
  }

}
