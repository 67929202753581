import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader: HTMLIonLoadingElement | null = null;

  constructor(
    public loadingController: LoadingController
  ) { }

  public async init(message?:string, duration?:number) {
    this.loader = await this.loadingController.create({
      message: message ? message: 'SmartDine is loading, Please wait..',
      duration: duration ? duration: 1500,
    });
  }

  /**
   * Shows auto hide loader
   * @param duration Duration in millie seconds.
   */
  showAutoHideLoader(duration = 2000 ) {
    this.loadingController.create({
      message: 'This Loader Will Auto Hide in 2 Seconds',
      duration,
    }).then((res) => {
      res.present();
      res.onDidDismiss().then((e) => {
        console.log('Loading dismissed!', e);
      });
    });

  }

  /**
   * Shows Loader.
   */
  async showLoader(message?: string, duration?:number) {

    await this.init(message, duration);
    await this.loader?.present();
    return new Promise<boolean>((resolve) => {
      setTimeout(() => {
        this.loader.dismiss().then(() => {
          resolve(true); // Resolve with true when loader is dismissed
        });
      }, duration);
    });
  }

  /**
   * Hides Loader.
   */
  hideLoader() {
    this.loader?.dismiss();
    this.init(); // creating new instance and keeping ready for next use..
  }


}
