export * from './cartRestControllerForAdmins.service';
import { CartRestControllerForAdminsService } from './cartRestControllerForAdmins.service';
export * from './cartRestControllerForUsers.service';
import { CartRestControllerForUsersService } from './cartRestControllerForUsers.service';
export * from './categoryProductMapRestController.service';
import { CategoryProductMapRestControllerService } from './categoryProductMapRestController.service';
export * from './categoryRestControllerForAdmin.service';
import { CategoryRestControllerForAdminService } from './categoryRestControllerForAdmin.service';
export * from './commonRestControllerForUsers.service';
import { CommonRestControllerForUsersService } from './commonRestControllerForUsers.service';
export * from './domainConfigRestController.service';
import { DomainConfigRestControllerService } from './domainConfigRestController.service';
export * from './domainConfigRestControllerForCommon.service';
import { DomainConfigRestControllerForCommonService } from './domainConfigRestControllerForCommon.service';
export * from './groupRestController.service';
import { GroupRestControllerService } from './groupRestController.service';
export * from './inventoryAlertController.service';
import { InventoryAlertControllerService } from './inventoryAlertController.service';
export * from './inventoryEntryController.service';
import { InventoryEntryControllerService } from './inventoryEntryController.service';
export * from './inventoryMasterController.service';
import { InventoryMasterControllerService } from './inventoryMasterController.service';
export * from './kitchenOrderRestController.service';
import { KitchenOrderRestControllerService } from './kitchenOrderRestController.service';
export * from './menuItemMapPriceRestController.service';
import { MenuItemMapPriceRestControllerService } from './menuItemMapPriceRestController.service';
export * from './menuItemMapRestController.service';
import { MenuItemMapRestControllerService } from './menuItemMapRestController.service';
export * from './menuItemMapStockRestController.service';
import { MenuItemMapStockRestControllerService } from './menuItemMapStockRestController.service';
export * from './menuItemRestController.service';
import { MenuItemRestControllerService } from './menuItemRestController.service';
export * from './menuRestController.service';
import { MenuRestControllerService } from './menuRestController.service';
export * from './menuUsersRestController.service';
import { MenuUsersRestControllerService } from './menuUsersRestController.service';
export * from './offerMappingRestController.service';
import { OfferMappingRestControllerService } from './offerMappingRestController.service';
export * from './offerRestControllerForUsers.service';
import { OfferRestControllerForUsersService } from './offerRestControllerForUsers.service';
export * from './offerRuleRestController.service';
import { OfferRuleRestControllerService } from './offerRuleRestController.service';
export * from './orderRestController.service';
import { OrderRestControllerService } from './orderRestController.service';
export * from './orderRestControllerForUsers.service';
import { OrderRestControllerForUsersService } from './orderRestControllerForUsers.service';
export * from './parentOrderRestController.service';
import { ParentOrderRestControllerService } from './parentOrderRestController.service';
export * from './paymentOptionRestController.service';
import { PaymentOptionRestControllerService } from './paymentOptionRestController.service';
export * from './paymentOptionRestControllerForUsers.service';
import { PaymentOptionRestControllerForUsersService } from './paymentOptionRestControllerForUsers.service';
export * from './paymentControllerForUsers.service';
import { PaymentControllerForUsersService } from './paymentControllerForUsers.service';
export * from './paymentControllerForAdmin.service';
import { PaymentControllerForAdminService } from './paymentControllerForAdmin.service';
export * from './productRestControllerForAdmin.service';
import { ProductRestControllerForAdminService } from './productRestControllerForAdmin.service';
export * from './profileRestControllerForUsers.service';
import { ProfileRestControllerForUsersService } from './profileRestControllerForUsers.service';
export * from './razorPayWebhookController.service';
import { RazorPayWebhookControllerService } from './razorPayWebhookController.service';
export * from './reportsRestControllerForAdmin.service';
import { ReportsRestControllerForAdminService } from './reportsRestControllerForAdmin.service';
export * from './restaurantBranchRestController.service';
import { RestaurantBranchRestControllerService } from './restaurantBranchRestController.service';
export * from './restaurantBranchTableRestController.service';
import { RestaurantBranchTableRestControllerService } from './restaurantBranchTableRestController.service';
export * from './restaurantContactRestController.service';
import { RestaurantContactRestControllerService } from './restaurantContactRestController.service';
export * from './restaurantGroupMapRestController.service';
import { RestaurantGroupMapRestControllerService } from './restaurantGroupMapRestController.service';
export * from './restaurantQrCodeRestController.service';
import { RestaurantQrCodeRestControllerService } from './restaurantQrCodeRestController.service';
export * from './restaurantRegistrationRestControllerForUsers.service';
import { RestaurantRegistrationRestControllerForUsersService } from './restaurantRegistrationRestControllerForUsers.service';
export * from './restaurantRestController.service';
import { RestaurantRestControllerService } from './restaurantRestController.service';
export * from './restaurantTaxRuleMapRestController.service';
import { RestaurantTaxRuleMapRestControllerService } from './restaurantTaxRuleMapRestController.service';
export * from './restaurantUsersRestController.service';
import { RestaurantUsersRestControllerService } from './restaurantUsersRestController.service';
export * from './smartdineImageRestController.service';
import { SmartdineImageRestControllerService } from './smartdineImageRestController.service';
export * from './statisticsRestController.service';
import { StatisticsRestControllerService } from './statisticsRestController.service';
export * from './taxRuleRestController.service';
import { TaxRuleRestControllerService } from './taxRuleRestController.service';
export * from './userGroupMapRestController.service';
import { UserGroupMapRestControllerService } from './userGroupMapRestController.service';
export * from './userRestController.service';
import { UserRestControllerService } from './userRestController.service';
export * from './restaurantCurrency.service';
import {RestaurantCurrencyService } from './restaurantCurrency.service';
export * from './redirectUser.service';
import { RedirectUserService } from './redirectUser.service';

export const APIS = [
  CartRestControllerForAdminsService,
  CartRestControllerForUsersService,
  CategoryProductMapRestControllerService,
  CategoryRestControllerForAdminService,
  CommonRestControllerForUsersService,
  DomainConfigRestControllerService,
  DomainConfigRestControllerForCommonService,
  GroupRestControllerService,
  InventoryAlertControllerService,
  InventoryEntryControllerService,
  InventoryMasterControllerService,
  KitchenOrderRestControllerService,
  MenuItemMapPriceRestControllerService,
  MenuItemMapRestControllerService,
  MenuItemMapStockRestControllerService,
  MenuItemRestControllerService,
  MenuRestControllerService,
  MenuUsersRestControllerService,
  OfferMappingRestControllerService,
  OfferRestControllerForUsersService,
  OfferRuleRestControllerService,
  OrderRestControllerService,
  OrderRestControllerForUsersService,
  ParentOrderRestControllerService,
  PaymentOptionRestControllerService,
  PaymentOptionRestControllerForUsersService,
  PaymentControllerForUsersService,
  PaymentControllerForAdminService,
  ProductRestControllerForAdminService,
  ProfileRestControllerForUsersService,
  RazorPayWebhookControllerService,
  ReportsRestControllerForAdminService,
  RestaurantBranchRestControllerService,
  RestaurantBranchTableRestControllerService,
  RestaurantContactRestControllerService,
  RestaurantGroupMapRestControllerService,
  RestaurantQrCodeRestControllerService,
  RestaurantRegistrationRestControllerForUsersService,
  RestaurantRestControllerService,
  RestaurantTaxRuleMapRestControllerService,
  RestaurantUsersRestControllerService,
  SmartdineImageRestControllerService,
  StatisticsRestControllerService,
  TaxRuleRestControllerService,
  UserGroupMapRestControllerService,
  UserRestControllerService,
  RestaurantCurrencyService,
  RedirectUserService,
];
