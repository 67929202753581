import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from '../../services/notification/notification.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  public notificationList: any[] = [];

  constructor(
    private notificationService: NotificationService, 
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.notificationService.notification$.subscribe(notifications => {
      this.notificationList = notifications;
    });

  }

  close() {
    this.popoverController.dismiss();
  }
}
