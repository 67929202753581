import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-help-support',
  template: `<ion-content>
    <ion-button
      fill="clear"
      class="close-icon"
      slot="start"
      (click)="closePopover()"
    >
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <ion-list class="">
      <ion-item lines="none">
        <p class="text-label">
          For help and support, please create ticket from
          <a
            [href]="getSupportLink()"
            class="text-decoration-none text-primary fw-600"
            target="_blank"
            >Help & Support</a
          >
        </p>
      </ion-item>
    </ion-list>
  </ion-content>`,
  styles: [
    `
      .text-label {
        font: normal normal normal 14px/21px Chivo;
        color: #0089ff;
      }
      .fw-600 {
        font-size: 16px !important;
        font-weight: 600 !important;
      }
      ion-content{
        position: relative;
      }
      .close-icon {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      ::ng-deep .custom-popover {
        position: absolute !important;
        left: -250px !important;
        top: -50px !important;
        right: auto;
      }
    `,
  ],
})
export class HelpSupportComponent {
  public static readonly id = 'help-support-modal';

  constructor(private popoverController: PopoverController) {}

  public getSupportLink(): string {
    return 'https://smartdinenow.raiseaticket.com';
  }

  public closePopover() {
    this.popoverController.dismiss();
  }
}
