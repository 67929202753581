import { CurrencyModel, RestaurantModelPrefTimeZone } from 'src/app/swagger';

export const COUNTRY_LIST = ['India', 'USA'];

export const INDIAN_STATES = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Lakshadweep',
  'Delhi',
  'Puducherry',
];

export const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const COUNTRY_CODE = ['+91', '+1'];

export const CURRENCY_CODES = ['INR', 'USD'];

export const CURRENCY_LIST: CurrencyModel[] = [
  { currencyCode: 'INR', currencysymbol: '₹', displayName: 'Indian Rupee' },
  { currencyCode: 'USD', currencysymbol: '$', displayName: 'US Dollar' },
];

export const TIME_ZONES: RestaurantModelPrefTimeZone[] = [
  {
    id: 'Asia/Kolkata',
    displayName: 'India Standard Time (IST)',
    dstsavings: 0,
    rawOffset: 5.5,
  },
  {
    id: 'US/Central',
    displayName: 'Central Standard Time',
    dstsavings: 1,
    rawOffset: -6,
  },
  {
    id: 'US/Eastern',
    displayName: 'Eastern Standard Time',
    dstsavings: 1,
    rawOffset: -5,
  },
  {
    id: 'US/Mountain',
    displayName: 'Mountain Standard Time',
    dstsavings: 1,
    rawOffset: -7,
  },
  {
    id: 'US/Pacific',
    displayName: 'Pacific Standard Time',
    dstsavings: 1,
    rawOffset: -8,
  },
];
